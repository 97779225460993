/*
 * @Description: AKJERRT
 * @Date: 2022-08-12 14:49:02
 * @LastEditTime: 2022-08-12 16:41:41
 * @FilePath: \linkgap-front\src\views\system\api\SysStationApi.js
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const sysUpgradeRecord = params => axios({
    url: '/api/base/system/sysUpgradeRecord/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
export const delSysUpgradeRecord = params => axios({
    url: '/api/base/system/sysUpgradeRecord/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editSysUpgradeRecord = params => axios({
    url: '/api/base/system/sysUpgradeRecord/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})